import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import App from 'src/components/App/index';
import { createGlobalStyle } from 'styled-components';
import theme from 'src/styles/theme';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4';
import { GATrackingId } from 'src/utils/constants';
import { isValidGaDomain } from 'src/utils/index';
import { GlobalProvider } from './context/GlobalContext';

if (isValidGaDomain()) {
  ReactGA.initialize(GATrackingId);
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Bitter', Arial, Helvetica, 'Noto Sans Devanagari', 'Noto Sans CJK SC Thin', 'Noto Sans SC', 'Noto Sans Hebrew', sans-serif;
    box-sizing: border-box;
    line-height: 1;
  }

  html, body {
    color: #384347;
    margin: 0;
  }

  h1, h2, h3, h4, h5, h6, p {
    font-weight: normal;
    margin: 0;
  }

  a {
    color: ${theme.colors.themeColorBlue};
    text-decoration: none;
  }

  ul, ol {
    margin: 0;
    padding: 0 0 0 ${theme.rem(theme.generalSpacing)};
  }

  @media screen and (max-width: ${theme.breakpoints.tablet}px) {
    ul, ol {
      padding: 0 0 0 ${parseFloat(theme.getMobileVw(theme.generalSpacing))}vw;
    }
  }

  @media print {
    @page {
      size: A4;
      margin: 1cm 1.5cm;
    }

    html, body {
      font-size: 8.5px;
    }

    * {
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
    }
  }
`;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <GlobalStyle />
    <ThemeProvider theme={theme}>
      <GlobalProvider>
        <App />
      </GlobalProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
