import React, { useEffect, useState, FC } from 'react';
import styled, { css } from 'styled-components';
import theme from 'src/styles/theme';
import { getData } from 'src/utils';
import { LOCALE } from 'src/utils/constants';
import { Locale } from 'src/types';
import Title from 'src/components/tiny/Title';
import QrCard from 'src/components/tiny/QrCard';
import Loader from 'src/components/tiny/Loader';

interface NetworkCardType {
  image: string;
  network: string;
  url: string;
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  locale?: Locale;
  qr?: string;
  size?: number;
}

const gap = theme.generalSpacing / 2;

const mobileStyling = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  > .qrcard-container {
    column-gap: ${theme.getMobileVw(gap)};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
`;

const tabletStyling = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  > .qrcard-container {
    display: flex;
    flex-direction: column;
    flex-wrap: no-wrap;
    row-gap: ${theme.rem(gap)};
    width: 100%;
  }
`;

const StyledComponent = styled.div`
  ${tabletStyling}

  @media screen and (max-width: ${theme.breakpoints.tablet}px) {
    ${mobileStyling}
  }

  @media print {
    ${tabletStyling}

    @media screen and (max-width: ${theme.breakpoints.tablet}px) {
      ${mobileStyling}
    }
  }
`;

const Network: FC<Props> = ({
  title,
  locale = LOCALE.DEFAULT,
  qr = '',
  size = 100,
  ...rest
}) => {
  const [data, setData] = useState<NetworkCardType[]>([]);

  useEffect(() => {
    setData([]);
    getData('network', locale).then((result) => {
      if (result.isSucceed) {
        setData(result.data);
      }
    });
  }, [locale]);

  return (
    <StyledComponent {...rest}>
      <Title title={title} caseType="UPPER" />
      <div className="qrcard-container">
        {data?.length > 0 ? (
          data.map((each, index) => {
            return (
              <QrCard
                className="qrcard"
                icon={each.image}
                key={index}
                // name={!isPrinting ? each.network : undefined}
                name={each.network}
                url={each.url}
                // iconImageSideBySideOnPrint={isPrinting}
              />
            );
          })
        ) : (
          <Loader />
        )}
      </div>
    </StyledComponent>
  );
};

export default Network;
