import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  LOCALE,
  LOCAL_STORAGE_KEYS,
  FORCE_USE_DUMMY_DATA
} from 'src/utils/constants';
import ReactGA from 'react-ga4';
import { isValidGaDomain } from 'src/utils';
import { Locale } from 'src/types';

type GlobalContextType = {
  isPrinting: boolean;
  useOfflineData: boolean;
  locale: Locale;
  setLocale: (locale: Locale) => void;
  copyRightDomain: string;
};

const GlobalContext = createContext<GlobalContextType>({
  isPrinting: false,
  useOfflineData: false,
  locale: LOCALE.DEFAULT,
  setLocale: () => {},
  copyRightDomain: ''
});

export const GlobalProvider = ({ children }: { children: ReactNode }) => {
  const [isPrinting, setIsPrinting] = useState(false);
  const [useOfflineData, setUseOfflineData] = useState(false);
  const [locale, setLocale] = useState<Locale>(LOCALE.DEFAULT);
  const copyRightDomain = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return (
      urlParams.get('copyright-domain') ||
      `${window.location.protocol}//${window.location.host}`
    );
  }, []);

  useEffect(() => {
    const handleBeforePrint = () => {
      setIsPrinting(true);

      if (isValidGaDomain()) {
        ReactGA.event({
          category: 'User Interaction',
          action: 'custom_print',
          label: `Print dialog opened`
        });
      }
    };

    const handleAfterPrint = () => {
      setIsPrinting(false);
    };

    window.addEventListener('beforeprint', handleBeforePrint);
    window.addEventListener('afterprint', handleAfterPrint);

    return () => {
      window.removeEventListener('beforeprint', handleBeforePrint);
      window.removeEventListener('afterprint', handleAfterPrint);
    };
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const printmode = urlParams.get('printmode');
    const offlineData = urlParams.get('useofflinedata');
    const newLocale = (() => {
      const urlLocale = urlParams.get('locale') as Locale;
      const storedLocale = localStorage.getItem(
        LOCAL_STORAGE_KEYS.LOCALE
      ) as Locale;
      const locale = urlLocale || storedLocale || LOCALE.DEFAULT;
      // Validate the locale
      if (locale !== Locale.English && locale !== Locale.Chinese) {
        return LOCALE.DEFAULT;
      }
      return locale;
    })();

    setIsPrinting(printmode?.toLowerCase() === 'true');
    setUseOfflineData(
      FORCE_USE_DUMMY_DATA || offlineData?.toLowerCase() === 'true'
    );
    setLocale(newLocale);
  }, []);

  const value: GlobalContextType = {
    isPrinting,
    useOfflineData,
    locale,
    setLocale,
    copyRightDomain
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

export const useGlobal = () => useContext(GlobalContext);
