import { cloneDeepWith, has, isArray, isObject } from 'lodash';
import {
  API_DELAY,
  API_HOST,
  API_REQUEST_TIMEOUT,
  JWT_TOKEN,
  LOCALE,
  FORCE_API_USE_TIMESTAMP,
  FORCE_USE_DUMMY_DATA
} from 'src/utils/constants';
import { Locale } from 'src/types';
import axios from 'axios';

const urlParams = new URLSearchParams(window.location.search);

export const totTitleCase = (str: string): string => {
  const strArr = str.toLowerCase().split(' ');
  const final: string[] = [];
  strArr.forEach((word) => {
    final.push(word.charAt(0).toUpperCase() + word.slice(1));
  });
  return final.join(' ');
};

export const caseConversion = (
  str: string,
  caseType?: 'UPPER' | 'LOWER' | 'TITLE'
): string => {
  let result = str;
  if (result) {
    switch (caseType) {
      case 'UPPER':
        result = str.toUpperCase();
        break;
      case 'LOWER':
        result = str.toLowerCase();
        break;
      case 'TITLE':
        result = totTitleCase(str);
        break;
      default:
        break;
    }
    return result;
  }
  return result;
};

export const generateUrl = (url = window.location.href, extraQrObj = {}) => {
  const tempUrl = new URL(url);
  const urlPath = `${tempUrl.protocol}//${tempUrl.host}${tempUrl.pathname}`;
  const qrStrFromUrL = tempUrl.search ? tempUrl.search.slice(1) : '';
  const qrObjFromUrl = Object.fromEntries(new URLSearchParams(qrStrFromUrL));
  const fullQrObj = { ...qrObjFromUrl, ...extraQrObj };
  const result = Object.keys(fullQrObj).length
    ? `${urlPath}?${new URLSearchParams(fullQrObj).toString()}`
    : urlPath;
  return result;
};

export const getAPI = (
  type: string,
  param: Record<string, string | number> = {}
): string => {
  const obj = {
    ...param,
    ...(FORCE_API_USE_TIMESTAMP ? { tt: new Date().getTime() } : {})
  };
  const newApiUrl = generateUrl(`${API_HOST}/resume/${type}`, obj);
  return newApiUrl;
};

export const getData = (
  section: string,
  locale: Locale = LOCALE.DEFAULT
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const loadDummyData = () => {
      import(`src/data/${section}`).then((dummyData) => {
        const result = getDataInLanguage(dummyData.default, locale);
        resolve({
          isSucceed: true,
          data: result
        });
      });
    };
    if (useOfflineData()) {
      loadDummyData();
    } else {
      setTimeout(() => {
        const apiUrL = getAPI(section, { language: locale });

        axios
          .get(apiUrL, {
            timeout: API_REQUEST_TIMEOUT,
            headers: {
              Authorization: `Bearer ${JWT_TOKEN}`
            }
          })
          .then((response) => {
            if (response.status === 200 && response.data) {
              resolve(response.data); // Resolve with the data
            } else {
              reject('No data'); // Reject with an error message
            }
          })
          .catch((err) => {
            loadDummyData();
            // reject(err); // Reject with the error
          });
      }, API_DELAY.getDelayTime());
    }
  });
};

export const isHtml = (str: string): boolean => /<[a-z][\s\S]*>/i.test(str);

export const getDataInLanguage = (
  data: any[] = [],
  locale: Locale
): any[] | undefined => {
  const isValidDataStructure = data && isArray(data) && data.length;

  if (!isValidDataStructure) {
    console.error('Invalid data structure');
    return;
  }

  if (!locale) {
    console.error('Invalid locale');
    return;
  }

  const result = cloneDeepWith(data, (item) => {
    const thisFieldHasMultipleLanguages = has(item, 'en') && has(item, 'zh');
    if (locale && thisFieldHasMultipleLanguages) {
      return item[locale];
    }
    if (!isObject(item)) {
      return item;
    }
  });

  return result;
};

export const imageToBase64 = (
  imageUrl: string
): Promise<string | ArrayBuffer | null> => {
  return fetch(imageUrl)
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
};

// check if domain is a valid google anaylytics domain
export const isValidGaDomain = () => {
  // G-2Z1RQ1LZP4 is the GA code for https://resume.pakcheong.com
  return window.location.origin === 'https://resume.pakcheong.com';
};

export const useOfflineData = () => {
  const offlineData = urlParams.get('useofflinedata');
  return FORCE_USE_DUMMY_DATA || offlineData?.toLocaleLowerCase() === 'true';
};
