import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'src/styles/theme';
import Header from 'src/components/Header';
import Employer from 'src/components/Employer';
import Education from 'src/components/Education';
import Knowledge from 'src/components/Knowledge';
import Language from 'src/components/Language';
import Network from 'src/components/Network';
import Work from 'src/components/Work';
import Footer from 'src/components/Footer';
import Translation from 'src/components/Translation';
import Loader from 'src/components/tiny/Loader';
import {
  Container,
  LeftPanel as OrignalLeftPanel,
  RightPanel as OriginalRightPanel
} from 'src/styles/styles';
import { useGlobal } from 'src/context/GlobalContext';
import { getData } from 'src/utils';
import { find } from 'lodash';
import { isValidGaDomain } from 'src/utils/index';
import ReactGA from 'react-ga4';

const leftPanelMobileStylings = css`
  display: flex;
  flex-direction: row;
  order: 2;
  row-gap: ${theme.getMobileVw(theme.generalSpacing)};
`;

const leftPanelTabletStylings = css`
  display: flex;
  flex-direction: column;
  order: 1;
  row-gap: ${theme.rem(theme.generalSpacing)};
`;

const rightPanelMobileStylings = css`
  display: flex;
  flex-direction: row;
  order: 1;
  row-gap: ${theme.getMobileVw(theme.generalSpacing)};
`;

const rightPanelTabletStylings = css`
  display: flex;
  flex-direction: column;
  order: 2;
  row-gap: ${theme.rem(theme.generalSpacing)};
`;

const LeftPanel = styled(OrignalLeftPanel)`
  ${leftPanelTabletStylings}

  @media screen and (max-width: ${theme.breakpoints.tablet}px) {
    ${leftPanelMobileStylings}
  }

  @media print {
    ${leftPanelTabletStylings}

    @media screen and (max-width: ${theme.breakpoints.tablet}px) {
      ${leftPanelMobileStylings}
    }
  }
`;

const RightPanel = styled(OriginalRightPanel)`
  ${rightPanelTabletStylings}

  @media screen and (max-width: ${theme.breakpoints.tablet}px) {
    ${rightPanelMobileStylings}
  }

  @media print {
    ${rightPanelTabletStylings}

    @media screen and (max-width: ${theme.breakpoints.tablet}px) {
      ${rightPanelMobileStylings}
    }
  }
`;

const mobileStyling = css`
  display: flex;
  flex-direction: column;
  padding-bottom: ${theme.getMobileVw(theme.generalSpacing)};
  padding-top: ${theme.getMobileVw(theme.generalSpacing / 2)};
  row-gap: ${theme.getMobileVw(theme.generalSpacing * 1.5)};
  width: 100%;

  .main {
    display: flex;
    flex-wrap: wrap;

    .leftpanel {
      order: 2;
    }

    .rightpanel {
      order: 1;
    }
  }
`;

const tabletStyling = css`
  display: flex;
  flex-direction: column;
  padding-bottom: ${theme.rem(theme.generalSpacing)};
  padding-top: ${theme.rem(theme.generalSpacing * 1.5)};
  row-gap: ${theme.rem(theme.generalSpacing * 1.5)};
  width: 100%;

  .main {
    display: flex;
    flex-wrap: wrap;

    .leftpanel {
      order: 1;
    }

    .rightpanel {
      order: 2;
    }
  }
`;

const StyledComponent = styled.div`
  ${tabletStyling}

  @media screen and (max-width: ${theme.breakpoints.tablet}px) {
    ${mobileStyling}
  }

  @media print {
    ${tabletStyling}
    padding-top: 0;
    padding-bottom: 0;

    @media screen and (max-width: ${theme.breakpoints.tablet}px) {
      ${mobileStyling}
    }
  }
`;

function App() {
  const { locale, setLocale } = useGlobal();
  const [siteTile, setSiteTitle] = useState<string>('');
  const [copyright, setCopyright] = useState<string>();
  const [educationTitle, setEducationTitle] = useState<string>();
  const [employerTitle, setEmployerTitle] = useState<string>();
  const [knowledgeTitle, setKnowledgeTitle] = useState<string>();
  const [languageTitle, setLanguageTitle] = useState<string>();
  const [networkTitle, setNetworkTitle] = useState<string>();
  const [workTitle, setWorkTitle] = useState<string>();
  const [titleIsLoaded, setTitleIsLoaded] = useState<boolean>(false);

  document.documentElement.lang = locale;

  useEffect(() => {
    getData('site', locale).then((result) => {
      if (result.isSucceed) {
        const siteData = find(result.data, { name: 'title' });
        setSiteTitle(siteData.data);

        const copyrightData = find(result.data, { name: 'copyright' });
        setCopyright(copyrightData.data);
      }
    });

    if (isValidGaDomain()) {
      // Track the initial language when the user visits the site
      ReactGA.event({
        category: 'User Interaction',
        action: 'custom_site_load',
        label: `Using Language: ${locale}`
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  useEffect(() => {
    document.title = siteTile;
  }, [siteTile]);

  useEffect(() => {
    setTitleIsLoaded(false);
    getData('title', locale).then((result) => {
      if (result.isSucceed) {
        const educationTitle = find(result.data, { id: 1 });
        const employerTitle = find(result.data, { id: 2 });
        const knowledgeTitle = find(result.data, { id: 3 });
        const languageTitle = find(result.data, { id: 4 });
        const networkTitle = find(result.data, { id: 5 });
        const workTitle = find(result.data, { id: 6 });
        setEducationTitle(educationTitle?.title || '');
        setEmployerTitle(employerTitle?.title || '');
        setKnowledgeTitle(knowledgeTitle?.title || '');
        setLanguageTitle(languageTitle?.title || '');
        setNetworkTitle(networkTitle?.title || '');
        setWorkTitle(workTitle?.title || '');
        setTitleIsLoaded(true);
      }
    });
  }, [locale]);

  return (
    <>
      {titleIsLoaded ? (
        <StyledComponent>
          <Header locale={locale} />
          <Container className="main">
            <LeftPanel>
              <Employer
                className="employer"
                locale={locale}
                title={employerTitle}
              />
              <Education
                className="education"
                locale={locale}
                title={educationTitle}
              />
            </LeftPanel>
            <RightPanel>
              <Knowledge
                className="knowledge"
                locale={locale}
                title={knowledgeTitle}
              />
              <Language
                className="language"
                locale={locale}
                title={languageTitle}
              />
              <Network
                className="network"
                locale={locale}
                title={networkTitle}
              />
              <Work className="work" locale={locale} title={workTitle} />
            </RightPanel>
          </Container>
          {copyright && <Footer className="footer" copyright={copyright} />}
          <Translation locale={locale} setLocale={setLocale} />
        </StyledComponent>
      ) : (
        <div style={{ height: '100vh', display: 'flex' }}>
          <Loader />
        </div>
      )}
    </>
  );
}

export default App;
