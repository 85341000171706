import React, { FC, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import theme from 'src/styles/theme';
import { API_HOST, LOCALE } from 'src/utils/constants';
import { getData } from 'src/utils';
import { Locale } from 'src/types';
import Heading from 'src/components/tiny/Heading';
import IconText from 'src/components/tiny/IconText';
import ImageComp from 'src/components/tiny/ImageComp';
import Loader from 'src/components/tiny/Loader';
import { TailSpin } from 'react-loader-spinner';
import {
  Container as OriginalContainer,
  LeftPanel as OriginalLeftPanel,
  RightPanel as OriginalRightPanel
} from 'src/styles/styles';
import profilePlaceholder from 'src/images/profile2.jpg';
import { useGlobal } from 'src/context/GlobalContext';

interface Props {
  locale?: Locale;
}

const infoFontSize = 15;
const infoGap = '5%';
const infoIconMarginBottom = 5;
const nameFontSize = 30;
const namePosMarginBottom = 10;
const positionFontSize = 25;
const profilePicSize = 128;

const mobileStyling = css`
  .name {
    font-size: ${theme.getMobileVw(nameFontSize)};
  }
  .position {
    font-size: ${theme.getMobileVw(positionFontSize / 1.15)};
  }
  .name,
  .position {
    flex-basis: 100%;
    margin-bottom: ${theme.getMobileVw(namePosMarginBottom)};
  }
  .info {
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;

    > * {
      color: inherit;
      flex-basis: 100%;
      flex-grow: 0;
      font-size: ${theme.getMobileVw(infoFontSize)};
      margin-right: 0;
      margin-bottom: ${theme.getMobileVw(infoIconMarginBottom)};

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .loader,
  .profilepic {
    border-radius: 50%;
    margin: 0 auto;
    width: ${theme.getMobileVw(profilePicSize)};
  }
  .loader {
    // container of loader-icon
    margin: 0 auto;
  }
`;

const tabletStyling = css`
  .name {
    font-size: ${theme.rem(nameFontSize)};
  }
  .position {
    font-size: ${theme.rem(positionFontSize)};
  }
  .name,
  .position {
    flex-basis: 100%;
    margin-bottom: ${theme.rem(namePosMarginBottom)};
  }
  .info {
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;

    > * {
      color: inherit;
      flex-basis: 50%;
      flex-grow: 1;
      font-size: ${theme.rem(infoFontSize)};
      margin-right: 0;
      margin-bottom: ${theme.rem(infoIconMarginBottom)};

      &.location {
        flex-basis: 100%;
        margin-bottom: 0;
      }
    }
  }
  .loader,
  .profilepic {
    border-radius: 50%;
    margin: 0 auto;
    width: ${theme.rem(profilePicSize)};
  }
`;

const leftPanelMobileStyling = css`
  flex-direction: row;
  align-items: center;
  order: 2;

  > *:last-child {
    margin-bottom: 0;
  }
`;

const leftPanelTabletStyling = css`
  flex-direction: row;
  align-items: center;
  order: 1;

  > *:last-child {
    margin-bottom: 0;
  }
`;

const LeftPanel = styled(OriginalLeftPanel)`
  ${leftPanelTabletStyling}

  @media screen and (max-width: ${theme.breakpoints.tablet}px) {
    ${leftPanelMobileStyling}
  }

  @media print {
    ${leftPanelTabletStyling}

    @media screen and (max-width: ${theme.breakpoints.tablet}px) {
      ${leftPanelMobileStyling}
    }
  }
`;

const rightPanelMobileStyling = css`
  flex-direction: row;
  margin-right: ${theme.getMobileVw(infoGap)};
  order: 1;
`;

const rightPanelTabletStyling = css`
  flex-direction: row;
  margin-right: 0;
  order: 2;
`;

const RightPanel = styled(OriginalRightPanel)`
  ${rightPanelTabletStyling}

  @media screen and (max-width: ${theme.breakpoints.tablet}px) {
    ${rightPanelMobileStyling}
  }

  @media print {
    ${rightPanelTabletStyling}

    @media screen and (max-width: ${theme.breakpoints.tablet}px) {
      ${rightPanelMobileStyling}
    }
  }
`;

const Container = styled(OriginalContainer)`
  ${tabletStyling}
  display: flex;
  flex-direction: row;

  @media screen and (max-width: ${theme.breakpoints.tablet}px) {
    ${mobileStyling}
    margin-bottom: ${theme.getMobileVw(theme.generalSpacing)};
  }

  @media print {
    ${tabletStyling}

    @media screen and (max-width: ${theme.breakpoints.tablet}px) {
      ${mobileStyling}
    }
  }
`;

const Header: FC<Props> = ({ locale = LOCALE.DEFAULT }) => {
  const { isPrinting } = useGlobal();
  const [profile, setProfile] = useState<any>();
  const [name, setName] = useState<string | null>(null);
  const [picture, setPicture] = useState<string | null>(null);

  useEffect(() => {
    setProfile(null);
    getData('profile', locale).then((result) => {
      if (result.isSucceed) {
        setProfile(result.data[0]);
      }
    });
  }, [locale]);

  useEffect(() => {
    if (!profile) {
      return;
    }
    const name = (() => {
      const { firstname, lastname } = profile;
      let newLastName,
        result = '';
      if (lastname) {
        newLastName = `<strong>${lastname}</strong>`;
      }
      if (newLastName && firstname) {
        result = `${newLastName}, ${firstname}`;
      } else if (newLastName && !firstname) {
        result = newLastName;
      } else if (!newLastName && firstname) {
        result = firstname;
      }
      return result;
    })();
    setName(name);
    setPicture(profile.picture ? `${API_HOST}${profile.picture}` : null);
  }, [profile]);

  return (
    <Container as={'header'}>
      {profile ? (
        <>
          <LeftPanel>
            {name && (
              <Heading
                caseType="UPPER"
                className="name"
                heading={name}
                tag="h1"
              />
            )}
            {profile?.position && (
              <Heading
                caseType="TITLE"
                className="position"
                heading={profile?.position}
                tag="p"
                type="sub"
              />
            )}
            {(profile?.telephone || profile?.email || profile?.location) && (
              <div className="info">
                {isPrinting && profile?.telephone && (
                  <IconText
                    href={`tel:${profile?.telephone.replace(/ /g, '')}`}
                    className="telephone"
                    icon="contact"
                    tag="a"
                    text={profile?.telephone}
                  />
                )}
                {isPrinting && profile?.email && (
                  <IconText
                    href={`mailto:${profile?.email}`}
                    className="email"
                    icon="envelope"
                    tag="a"
                    text={profile?.email}
                  />
                )}
                {profile?.location && (
                  <IconText
                    className="location"
                    icon="gps-pink"
                    text={profile?.location}
                  />
                )}
              </div>
            )}
          </LeftPanel>
          {picture && (
            <RightPanel>
              <ImageComp
                className="profilepic"
                alt={profile.picture_alt}
                src={picture}
                placeholder={profilePlaceholder}
                loadingIcon={
                  <TailSpin
                    height="100%"
                    width="100%"
                    color={theme.colors.themeColorPink}
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass="loader-icon"
                    visible={true}
                  />
                }
              />
            </RightPanel>
          )}
        </>
      ) : (
        <Loader />
      )}
    </Container>
  );
};

export default Header;
