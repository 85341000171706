import { Locale } from 'src/types';

export const IS_DEV = process.env.NODE_ENV === 'development';

const PROTOCOL =
  typeof window !== 'undefined' &&
  typeof window.location !== 'undefined' &&
  typeof window.location.protocol !== 'undefined'
    ? window.location.protocol
    : 'http:';
// const LIVEHOST = `${PROTOCOL}//api.pakcheong.com`;
export const LIVEHOST = 'https://api.pakcheong.com';
export const LOCALHOST = `${PROTOCOL}//localhost:3333`;
export const API_HOST = `${IS_DEV ? LOCALHOST : LIVEHOST}/api`;
export const FORCE_USE_DUMMY_DATA = false;
export const FORCE_API_USE_TIMESTAMP = true; // this is ignored when FORCE_USE_DUMMY_DATA is true
export const GATrackingId = 'G-2Z1RQ1LZP4'; // google analytics tracking ID for https://resume.pakcheong.com

export const JWT_TOKEN =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEyMzQsInVzZXJuYW1lIjoiZmFrZXVzZXIiLCJyb2xlIjoidXNlciIsImlhdCI6MTcwMDY3Mjc3Nn0.dZ_5_f6QktlrlFP7ytSPbCe8lYgmE1tE4rH6VOWTsiA';

export const API_DELAY: {
  ENABLE: boolean;
  START_TIME: number;
  END_TIME: number;
  getDelayTime: () => number;
} = {
  ENABLE: false, // to delay every API call for testing purposes
  START_TIME: 1000,
  END_TIME: 5000,
  getDelayTime: () => 0
};
API_DELAY.getDelayTime = () => {
  return API_DELAY.ENABLE
    ? Math.floor(
        Math.random() * (API_DELAY.END_TIME - API_DELAY.START_TIME + 1)
      ) + API_DELAY.START_TIME
    : 0;
};
export const API_REQUEST_TIMEOUT = 3000; // Axios default is 3000

export const LOCALE = {
  DEFAULT:
    typeof navigator !== 'undefined' && navigator.language.startsWith('zh')
      ? Locale.Chinese
      : Locale.English,
  EN: Locale.English,
  ZH: Locale.Chinese
};

export const LOCAL_STORAGE_KEYS = {
  LOCALE: 'locale'
};
