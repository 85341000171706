import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'src/styles/theme';
import Title from 'src/components/tiny/Title';
import Meter from 'src/components/tiny/Meter';
import Loader from 'src/components/tiny/Loader';
import { getData } from 'src/utils';
import { LOCALE } from 'src/utils/constants';
import { Locale } from 'src/types';

interface LanguageType {
  language: string;
  proficiency: number;
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  locale?: Locale;
}

const gap = '5%';
const rowGap = theme.generalSpacing / 2;

const mobileStyling = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  > .meter-container {
    display: flex;
    flex-direction: column;
    row-gap: ${theme.getMobileVw(rowGap)};
    width: 100%;

    > .meter {
      flex: 0 0 calc((100% - ${theme.getMobileVw(gap)}) / 2);
    }
  }
`;

const tabletStyling = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  > .meter-container {
    display: flex;
    flex-direction: column;
    row-gap: ${theme.rem(rowGap)};
    width: 100%;

    > .meter {
      flex-basis: 100%;
    }
  }
`;

const StyledComponent = styled.div`
  ${tabletStyling}

  @media screen and (max-width: ${theme.breakpoints.tablet}px) {
    ${mobileStyling}
  }

  @media print {
    ${tabletStyling}

    @media screen and (max-width: ${theme.breakpoints.tablet}px) {
      ${mobileStyling}
    }
  }
`;

const Language: FC<Props> = ({ title, locale = LOCALE.DEFAULT, ...rest }) => {
  const [data, setData] = useState<LanguageType[]>([]);

  useEffect(() => {
    setData([]);
    getData('language', locale).then((result) => {
      if (result.isSucceed) {
        setData(result.data);
      }
    });
  }, [locale]);

  return (
    <StyledComponent {...rest}>
      <Title title={title} caseType="UPPER" />
      <div className="meter-container">
        {data?.length > 0 ? (
          data.map((each, index) => {
            return (
              <Meter
                name={each.language}
                level={each.proficiency}
                key={index}
              />
            );
          })
        ) : (
          <Loader />
        )}
      </div>
    </StyledComponent>
  );
};

export default Language;
