import React, { useEffect, useState, FC } from 'react';
import { TailSpin } from 'react-loader-spinner';
import theme from 'src/styles/theme';
import axios from 'axios';
import { API_DELAY } from 'src/utils/constants';
import { imageToBase64 } from 'src/utils';
import qrcode, { QRCodeToDataURLOptions } from 'qrcode';
import { useGlobal } from 'src/context/GlobalContext';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  src: string;
  alt?: string;
  useBase64?: boolean;
  placeholder?: string;
  loadingIcon?: React.ReactNode;
}

const ImageComp: FC<Props> = ({
  src,
  alt = '',
  useBase64 = true,
  placeholder,
  loadingIcon,
  ...rest
}) => {
  const [source, setSource] = useState<string | null>(null);
  const { useOfflineData } = useGlobal();

  const getQrCodeFromUrl = (url: string): Promise<string | null> => {
    const urlParam = new URLSearchParams(new URL(url).search);
    return qrcode.toDataURL(urlParam.get('c') || '', {
      errorCorrectionLevel: urlParam.get('e') || 'L',
      margin: urlParam.get('m') || 1,
      scale: 4,
      width: urlParam.get('s') || 100,
      color: {
        dark: '#000000ff',
        light: '#ffffffff'
      },
      type: 'image/png'
    } as QRCodeToDataURLOptions);
  };

  useEffect(() => {
    const srcUrl = new URL(src);

    if (useBase64) {
      if (useOfflineData) {
        if (srcUrl.pathname === '/api/view') {
          imageToBase64(placeholder || src).then((result) => {
            setSource(result as string | null);
          });
        } else if (srcUrl.pathname === '/api/qr') {
          getQrCodeFromUrl(src).then((result) => {
            setSource(result);
          });
        }
      } else {
        if (srcUrl.pathname === '/api/view') {
          setTimeout(() => {
            axios
              .get(src)
              .then(() => {
                imageToBase64(src).then((result) => {
                  setSource(result as string | null);
                });
              })
              .catch(() => {
                if (placeholder) {
                  imageToBase64(placeholder).then((result) => {
                    setSource(result as string | null);
                  });
                } else {
                  setSource(src);
                }
              });
          }, API_DELAY.getDelayTime());
        } else if (srcUrl.pathname === '/api/qr') {
          setTimeout(() => {
            getQrCodeFromUrl(src).then((result) => {
              setSource(result);
            });
          }, API_DELAY.getDelayTime());
        }
      }
    } else {
      if (useOfflineData) {
        if (srcUrl.pathname === '/api/view') {
          if (placeholder) {
            setSource(placeholder);
          }
        } else if (srcUrl.pathname === '/api/qr') {
          getQrCodeFromUrl(src).then((result) => {
            setSource(result);
          });
        }
      } else {
        setSource(src); // original url
      }
    }
  }, [placeholder, src, useBase64, useOfflineData]);

  const ImageLoader = (
    <div className="loader">
      {loadingIcon || (
        <TailSpin
          height="80"
          width="80"
          color={theme.colors.themeColorPink}
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      )}
    </div>
  );

  const result = source ? (
    <img alt={alt} src={source} {...rest} />
  ) : (
    ImageLoader
  );

  return result;
};

export default ImageComp;
