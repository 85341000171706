import React, { FC, ReactElement, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import theme from 'src/styles/theme';

const mobileBaseLineStyles = css`
  border-bottom-width: 1px;
  border-bottom-color: #ccc;
  padding-bottom: ${theme.getMobileVw(theme.generalSpacing)};
  width: 100%;
`;

const tabletBaseLineStyles = css`
  border-bottom-width: 1px;
  border-bottom-color: #ccc;
  padding-bottom: ${theme.rem(theme.generalSpacing)};
  width: 100%;
`;

const baseLineStyles = css`
  ${tabletBaseLineStyles}

  @media screen and (max-width: ${theme.breakpoints.tablet}px) {
    ${mobileBaseLineStyles}
  }

  @media print {
    ${tabletBaseLineStyles}

    @media screen and (max-width: ${theme.breakpoints.tablet}px) {
      ${mobileBaseLineStyles}
    }
  }
`;

const DashedLine = styled.div`
  ${baseLineStyles}
  border-bottom-style: dashed;
`;

const DottedLine = styled.div`
  ${baseLineStyles}
  border-bottom-style: dotted;
`;

const SolidLine = styled.div`
  ${baseLineStyles}
  border-bottom-style: solid;
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  WrappedComponent: ReactElement;
  type?: 'dashed' | 'dotted' | 'solid';
  color?: string;
  thickness?: string;
  children?: ReactNode;
}

const Separator: FC<Props> = ({
  WrappedComponent,
  type = 'dashed',
  color = '#ccc',
  thickness = '1px',
  children,
  ...rest
}) => {
  let LineComponent;

  switch (type) {
    case 'dotted':
      LineComponent = DottedLine;
      break;
    case 'solid':
      LineComponent = SolidLine;
      break;
    default:
      LineComponent = DashedLine;
  }

  return (
    <LineComponent
      style={{ borderBottomColor: color, borderBottomWidth: thickness }}
      {...rest}
    >
      {children || WrappedComponent}
    </LineComponent>
  );
};

export default Separator;
